$dark-blue:   #262734;
$blue-grey:   #263238;
$grey:        #424242;
$light-green: #70A532;
$yellow:      #FBC02D;
$orange:      #E98F2E;
$brown:       #715146;
$green:       #279566;
$teal:        #178D81;
$cyan:        #37A9B7;
$blue:        #3583CA;
$indigo:      #465BD4;
$purple:      #6D45BC;
$pink:        #E53B75;
$red:         #D6494B;

$font-family: 'Poppins', sans-serif;
$box-shadow:  0 1px 15px 1px rgba(69,65,78,.08);
$border-color: #efefef;

$md-radio-checked-color: rgb(51, 122, 183);
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;