/*--------------------------------------------------------------
# default
--------------------------------------------------------------*/

body {
    font-family: 'Montserrat', sans-serif;
}

.light_text {
    font-weight: 300;
}

.strong_text {
    font-weight: 700;
}

.form-control {
    border-radius: 0;
    &:focus {
        box-shadow: none;
        border-color: #53b0d7;
    }
}

a {
    text-decoration: none !important;
    outline: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.banner {
    position: relative;
    background-size: cover !important;
    .banner__content {
        padding: 125px 0 50px;
        text-align: center;
        h1 {
            color: #fff;
            font-weight: 500;
            font-size: 46px;
            margin-bottom: 20px;
            position: relative;
            @media screen and (max-width: 767px){
                font-size: 30px;
            }
        }
        p {
            color: #fff;
            font-size: 18px;
            line-height: 28px;
            opacity: 0.8;
            margin-top: 15px;
        }

    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #53b0d7;
        background: -moz-linear-gradient(top, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
        background: -webkit-linear-gradient(top, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
        background: linear-gradient(to bottom, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#53b0d7', endColorstr='#44c4a7', GradientType=0 );
        opacity: .9;
    }
}

.area {
    padding: 50px 0;
    h2 {
        color: $blue;
        font-weight: 300;
        margin-bottom: 30px;
        font-size: 30px;
        letter-spacing: -.03em;
    }
    p {
        color: #3c3c3b;
        font-size: 16px;
        line-height: 1.88;
    }
    .fixed_image {
        position: absolute;
        right: 0;
        top: 70px;
        padding-bottom: 0;
        @media screen and (max-width: 991px){
            display: none;
        }
        img {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        }
    }
    img {
        width: 100%;
    }
    h3 {
        font-size: 40px;
        letter-spacing: -.04em;
        margin-top: 0;
        margin-bottom: 32px;
        @media screen and (max-width: 767px){
            margin-top: 15px;
            font-size: 25px;
        }
    }
    .block_content {
        padding: 5px 0;

        h4 {
            color: #fff;
            margin-bottom: 10px;
        }
        strong {
            color: #fff;
        }
        p {
            color: #fff;
            font-size: 13px;
            line-height: 1.5;
            margin-bottom: 10px;
        }
    }
    .modules_list {
        .module {
            padding: 45px 12% 0 8%;
            background: #fff;
            .module__icon {
                text-align: center;
                margin-bottom: 20px;
                img {
                    width: 80px;
                }
            }
            .module__content {

                h3 {
                    display: block;
                    font-size: 19px;
                    font-weight: 500;
                    line-height: 28px;
                    margin-bottom: 10px;
                }
                a {
                    font-size: 15px;
                    font-weight: 600;
                }
                p {
                    font-size: 14px;
                    min-height: 100px;
                }
            }
        }
    }
    &.background {
        position: relative;
        margin-bottom: 50px;
        h3 {
            color: #fff;
            font-weight: 600;
        }
        p {
            width: 95%;
            font-weight: 500;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(42, 63, 68, 0.85);
            width: 100%;
            height: 100%;
            opacity: .9;
        }
    }
}

.facts {
    background: #1ba395;
    padding: 75px 0;
    position: relative;
    .fact {
        text-align: center;
        p {
            color: #fff;
            font-weight: 500;
            line-height: 100%;
            &.counter {
                font-weight: 700;
                font-size: 40px;
                letter-spacing: -1px;
            }
        }
        @media screen and (max-width: 767px){
            margin: 10px 0;
            p {
                font-size: 20px !important;
            }
        }

    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(42, 63, 68, 0.85);
        width: 100%;
        height: 100%;
        opacity: .9;
    }
}

.slider {
    padding: 150px 0 50px;
    position: relative;
    background-size: cover !important;
    .slider__content {
        h1 {
            color: #fff;
            font-size: 40px;
        }
        h6 {
            color: #fff;
            opacity: .7;
            margin-top: 30px;
        }
        p {
            color: #fff;
            opacity: .8;
            font-size: 16px;
            line-height: 160%;
            margin: 50px 0;
        }
        img {
            width: 100%;
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            @media screen and (max-width: 767px){
                display: none;
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(42, 63, 68, 0.85);
        width: 100%;
        height: 100%;
        opacity: .9;
    }
}

.primary-btn {
    background: $blue;
    line-height: 42px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    position: relative;
    -webkit-animation: bg-pan-left 8s infinite;
    animation: bg-pan-left 8s infinite;
    &:focus {
        outline: none;
    }
    span {
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-60%);
        right: 30px;
    }
    &:hover {
        color: #fff;
        span {
            color: #fff;
            right: 20px;
        }
    }
    &.white {
        border: 1px solid #fff;
        color: #fff;
        span {
            color: #fff;
        }
        &:hover {
            background: #fff;
            color: $blue;
            span {
                color: $blue;
            }
        }
    }
}

.primary-btn.wh{
    background:#fff;
    color:#222;
    text-transform: uppercase;
    &:hover{
        background:#222;
        color:#fff;
    }
}


.primary-btn.squire {
    border-radius: 0px!important;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
    }
}

.overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.footer {
    background: #222222;
    .top {
        padding: 70px 0;
        h5 {
            font-size: 15px;
            color: #fff;
            margin-bottom: 30px;
            text-transform: uppercase;
            position: relative;
            &:before {
                position: absolute;
                left: 0;
                bottom: -5px;
                height: 2px;
                width: 35px;
                background-color: $blue;
                content: "";
            }
        }
        p {
            color: #fff;
            font-size: 12px;
            text-align: justify;
            line-height: 1.2;

        }
        img {
            width: 100%;
            margin-bottom: 20px;
        }
        ul {
            li {
                font-size: 15px;
                color: #fff;
                position: relative;
                display: block;
                line-height: 20px;
                margin-bottom: 15px;
                i {
                    color: $blue;
                }
                a {
                    font-size: 15px;
                    color: #fff;
                    transition: color .3s;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
    .bottom {
        background: #222;

        text-align: center;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        opacity: .8;
        .container {
            padding: 20px;
            border-top: 1px solid #4e4e4e;
        }
    }

}

.back_top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #53b0d7;
    background: -moz-linear-gradient(top, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
    background: -webkit-linear-gradient(top, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
    background: linear-gradient(to bottom, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#53b0d7', endColorstr='#44c4a7', GradientType=0 );
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    opacity: 0;
    z-index: -1;
    transition: .3s all;
    i {
        color: #fff;
        position: absolute;
        top: 0;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 50px;
        font-size: 30px;
        font-weight: 300;
        animation: plus-out 0.3s;
        transition: all 0.3s;
    }
    &.active {
        z-index: 999;
        opacity: .9;
    }
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
}

.overflow_hidden {
    overflow: hidden !important;
}

.super_btn {
    background: #53b0d7;
    background: -moz-linear-gradient(left, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
    background: -webkit-linear-gradient(top, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
    background: linear-gradient(to right, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#53b0d7', endColorstr='#44c4a7', GradientType=0 );
    background-size: 140% auto;
    font-size: 14px;
    line-height: 42px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.16);
    transition: all .3s;
    -webkit-animation: bg-pan-left 8s infinite;
    animation: bg-pan-left 8s infinite;
    &:focus {
        outline: none;
    }
   &:hover {
       color: #fff;
       background-position: right center;
       box-shadow: 0 14px 28px rgba(0,0,0,0.22), 0 10px 10px rgba(0,0,0,0.18);
   }
    @media screen and (max-width: 768px){
        text-align: center;
        width: 100% !important;
    }

}

.contact_boxes {
    @media screen and (max-width: 768px){
        margin-top: 30px;
    }
    .contact_box {
        margin-bottom: 30px;
        h4 {
            font-size: 20px;
        }
        ul {
            li {
                margin-top: 15px;
                i {
                    font-size: 20px;
                    color: $blue;
                }
                span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}

.form_errors {
    padding: 10px 0;
    color: $red;
    span {
        padding: 5px 0;

        display: block;
    }
}

.form_success {
    padding: 15px 0;
    display: block;
    color: $green;
}

.form-group.error {
    .form-control, .rc-anchor-light.rc-anchor-normal {
        border:1px solid $red !important;
    }
    span {
        font-size: 12px;
        color: $red !important;;
    }
}

.privacy_policy_page {
    h4 {
        font-weight: 600;
    }
    p {
        font-size: 14px;
    }
    ul {
        margin: 15px 0;
        padding-left: 25px;
        li {
            margin-bottom: 5px;
        }
    }
}

.services {
    h3 {
        font-size: 32px;
        font-weight: 800;
        line-height: 1.504;
        letter-spacing: -2px;
    }
    ul {
        li {
            margin-bottom: 15px;
            i {
                font-size: 18px;
                font-weight: 600;
                color: $blue;
                margin-right: 15px;
                vertical-align: text-top;
            }
        }
    }
}

.solutions {
    .solution__image {
        text-align: center;
        margin-bottom: 20px;
        img {
            width: 120px;
        }
    }
}

.module {
    img {
        width: 128px;
    }
}

.pricing {
    .pricing__table_container, .more_information {
        overflow-x: auto;
        table {
            th.features {
                width: 40%;
                vertical-align: inherit;
            }
            th.basic, th.classic, th.full {
                text-align: center;
                width: 20%;
                span {
                    display: block;
                    min-height: 55px;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            tbody {
                font-size: 13px;
                .description {
                    background: #efefef;
                    height: 65px;
                    td {
                        text-align: left !important;
                        vertical-align: middle;
                        font-weight: 500;
                    }
                }
                tr {
                    td {
                        text-align: center;
                        i {
                            color: $blue;
                            font-weight: 600;
                            font-size: 15px;
                        }
                    }
                    td:first-child {
                        text-align: left;
                    }
                }
            }
        }
    }
    .more_information {
        .table_container {
            margin-top: 30px;
        }
    }
    .legend {
        p {
            font-size: 13px;
            color: $blue;
        }
    }
}

.subscription {
    .steps__content {
        .step {
            display: none;
            &.active {
                display: block !important;
            }
        }
    }
    .steps__aside {
        .step__info {
            text-align: center;
            margin-bottom: 40px;
            opacity: .4;
            cursor: pointer;
            transition: opacity .3s;
            img {
                width: 65px;
            }

            h4 {
                margin: 10px 0;
                font-size: 16px;
                letter-spacing: 0.2px;
                font-weight: 700;
                text-transform: uppercase;
            }
            p {
                width: 80%;
                margin: auto;
                font-size: 13px;
            }
            &.active {
                opacity: 1;
            }
            &:hover {
                opacity: 1;
            }
        }

    }
}

.terms_service {
    h2 {
        color: #222;
        font-weight: 600;
    }
    p {
        font-size: 14px;
    }
}

.upload {
    input {
        position: absolute;
        margin-top: 3px;
        margin-left: 3px;
        height: 1px;
        width: 1px;
        z-index: -5;
    }
    .upload__button {
        max-width: 80%;
        font-size: 14px;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        color: #fff;
        background: #53b0d7;
        background: -moz-linear-gradient(left, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
        background: -webkit-linear-gradient(top, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
        background: linear-gradient(to right, #53b0d7 0%, #4cb9c1 44%, #44c4a7 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#53b0d7', endColorstr='#44c4a7', GradientType=0 );
        background-size: 140% auto;
        padding: 0.625rem 1.25rem;
        span {
            padding: 5px;
        }
    }
    svg {
        fill: #fff;
    }
}

$md-checkbox-margin: 10px 0;
$md-checkbox-checked-color: rgb(51, 122, 183);
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-size: 20px;
$md-checkbox-padding: 4px;

$md-checkmark-width: 2px;
$md-checkmark-color: #fff;

.md-checkbox {
    position: relative;
    margin: $md-checkbox-margin;
    text-align: left;

    &.md-checkbox-inline {
        display: inline-block;
    }

    label {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        &:before, &:after {
            content: "";
            position: absolute;
            left:0;
            top: 0;
        }

        &:before {
            // box
            width: $md-checkbox-size;
            height: $md-checkbox-size;
            background: #fff;
            border: 2px solid $md-checkbox-border-color;
            border-radius: 2px;
            cursor: pointer;
            transition: background .3s;
        }

        &:after {
            // checkmark
        }
    }

    input[type="checkbox"] {
        outline: 0;
        margin-right: $md-checkbox-size - 10px;
        visibility: hidden;

        &:checked {
            + label:before{
                background: $md-checkbox-checked-color;
                border:none;
            }
            + label:after {

                $md-checkmark-size: $md-checkbox-size - 2*$md-checkbox-padding;

                transform: rotate(-45deg);

                top: ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
                left: $md-checkbox-padding;
                width: $md-checkmark-size;
                height: $md-checkmark-size / 2;

                border: $md-checkmark-width solid $md-checkmark-color;
                border-top-style: none;
                border-right-style: none;
            }
        }

        &:disabled {
            + label:before{
                border-color: $md-checkbox-border-color-disabled;
            }
            &:checked {
                + label:before{
                    background: $md-checkbox-checked-color-disabled;
                }
            }
        }
    }
}

#siv_pack {
    option {
        span {
            display: inline-block;

        }
    }
}

@-webkit-keyframes bg-pan-left {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: right center;
    }
}
@keyframes bg-pan-left {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: right center;
    }
}

@-webkit-keyframes kenburns-top {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }
    100% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}
@keyframes kenburns-top {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }
    100% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}

