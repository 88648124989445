/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
    @media screen and (max-width: 767px){
        height: 60px;
    }
    #logo {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        img {
            width: 150px;
            transition: width .3s;
            @media screen and (max-width: 767px){
                position: absolute;
                left: 25px;
            }
        }
    }
    .nav_menu {
        .burger {
            display: block;
            position: fixed;
            top: 20px;
            right: 25px;
            z-index: 1000;
            transform: rotate(0deg);
            transition: all 500ms;
            .bar {
                display: block;
                width: 35px;
                height: 4px;
                margin: 6px auto;
                background: #fff;
                transition: all 500ms;
            }
            @media screen and (min-width: 768px){
                display: none;
            }
        }
        > ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            li {
                position: relative;
                a {
                    padding-right: .7rem;
                    padding-left: .7rem;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    transition: .3s color;
                    &:hover {
                        color: #222 !important;
                    }
                }
                .dropdown-menu {
                    display: block;
                    box-shadow: 0 1px 2px 3px rgba(0, 0, 0, 0.1);
                    border: none;
                    top: -1000px !important;
                    opacity: 0;
                    transform: translate3d(0,-10px,0) scaleY(1);
                    transition: transform .25s cubic-bezier(.17,.67,.52,1),opacity .1s linear,top .25s step-start,
                    -webkit-transform .25s cubic-bezier(.17,.67,.52,1);
                    transition-delay: .3s;
                    @media screen and (max-width: 768px){
                        display: none !important;
                    }
                    .dropdown-menu__content {
                        width: 300px;
                        ul {
                            li {
                                padding: 10px 0;
                                a {
                                    font-weight: 500;
                                    opacity: .8;
                                    color: $blue-grey !important;
                                    img {
                                        width: 25px;
                                        margin-right: 10px;
                                        filter: grayscale(100%);
                                        -webkit-filter: grayscale(100%);
                                        -moz-filter: grayscale(100%);
                                        -ms-filter: grayscale(100%);
                                        -o-filter: grayscale(100%);
                                        transition: .3s;
                                    }
                                }
                                &:hover {
                                    a {
                                        color: $teal !important;
                                        opacity: 1;
                                        img {
                                            filter: grayscale(0%);
                                            -webkit-filter: grayscale(0%);
                                            -moz-filter: grayscale(0%);
                                            -ms-filter: grayscale(0%);
                                            -o-filter: grayscale(0%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.active {
                    a {
                        color: #222 !important;
                    }
                }
                &:hover {
                    .dropdown-menu {
                        transform: translate3d(0,0,0) scaleY(1);
                        top: 25px !important;
                        opacity: 1;
                    }
                }
            }
            @media screen and (max-width: 767px){
                height: 0;
                z-index: -1;
                opacity: 0;
                background: transparent;
                transition: .3s background;
            }
        }
        &.active_mobile {
            overflow: hidden;
            .burger {
                top: 27px;
                transform: rotate(225deg);
                transition: all 500ms;
                transform-origin: center center;
                .bar {
                    transition: all 500ms;
                    &:nth-child(2) {
                        opacity: 0;
                        transition: opacity 0s;
                    };
                    &:nth-child(3) {
                        margin-top: -20px;
                        transform: rotate(90deg);
                    }
                }
            }
            ul {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 20%;
                background: rgba(42, 63, 68, 0.95);
                opacity: 1;
                z-index: 99;
                li {
                    text-align: center;
                    a {
                        color: #fff !important;
                        display: block;
                        margin-top: 40px;
                        font-size: 30px;
                    }
                }
            }
        }
    }

    &.active {
        background: #fff;
        box-shadow: 0 1px 2px 3px rgba(0, 0, 0, 0.1);
        #logo {
            img {
                width: 100px;
            }
        }
        .nav_menu {
            .burger {
                top: 13px;
                .bar {
                    background: #222;
                }
            }
            &.active_mobile {
                .burger {
                    top: 22px;
                }
            }
            ul {
                li {
                    a {
                        color: #222;
                        &:hover {
                            color: #44c4a7 !important;
                        }
                    }
                    &.active {
                        a {
                            color: #44c4a7 !important;
                        }
                    }
                }
            }
        }
    }
}
